import { styled } from '@mui/system'
import { ActionsWrapper } from '../tile-image-column.styled'
import { AdditionalLabelsWrapper } from '../tile.styled'

const WishListWrapper = styled('div')``
const ImageColumnWrapper = styled('div')`
	cursor: pointer;
	position: relative;
	> a {
		display: flex;
		max-width: 100%;
		width: 100%;
	}
`
const TextColumnWrapper = styled('div')`
	padding: 10px 0;
	${({ theme }) => theme.breakpoints.up('md')} {
		opacity: 0;
		position: absolute;
		width: calc(100% - var(--spacing-2));
		background-color: rgba(255, 255, 255, 0.3);
		backdrop-filter: blur(100px);
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		padding: var(--spacing-1);
		transition: all 0.3s ease-in-out;
	}
`

const RelatedAttributesWrapper = styled('div')`
	position: absolute;
	bottom: var(--spacing-1);
	left: var(--spacing-1);

	.MuiToggleButton-root {
		border: 1px solid ${({ theme }) => theme.palette.primary.main};
		border-radius: 0;
		padding: 0;
		width: 12px;
		height: 12px;
		margin: 0;

		span {
			border: none;
		}

		&.Mui-selected {
			border: 1px solid ${({ theme }) => theme.palette.primary.main};
		}
	}
`

const TileWrapper = styled('div')`
	position: relative;

	&:hover {
		${({ theme }) => theme.breakpoints.up('md')} {
			${TextColumnWrapper} {
				opacity: 1;
				bottom: var(--spacing-1);
			}
			${ActionsWrapper} {
				opacity: 1;
				top: var(--spacing-1);
			}

			${AdditionalLabelsWrapper} {
				opacity: 1;
				top: var(--spacing-1);
			}
		}
	}
`

export { TileWrapper, ImageColumnWrapper, TextColumnWrapper, WishListWrapper, RelatedAttributesWrapper }
