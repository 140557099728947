import { styled } from '@mui/system'

const ActionsWrapper = styled('div')``

const ImageColumnContainer = styled('div')`
	position: relative;
	display: block; /*  Can be removed once this issue is resolved https://github.com/mui/material-ui/issues/29078#issuecomment-1036208915 */
	${ActionsWrapper} {
		position: absolute;
		top: var(--spacing-1);
		right: var(--spacing-1);

		${({ theme }) => theme.breakpoints.up('md')} {
			opacity: 0;
			top: 0;
			transition: all 0.3s ease-in-out;
		}
	}
`

export { ImageColumnContainer, ActionsWrapper }
