import { styled } from '@mui/system'
import Typography from '../../atoms/typography'
import ActionAddToCart from '../action-add-to-cart'

const shouldForwardProp = (prop) => !['afterImage', 'bgColor', 'marginTop', 'position', 'variant'].includes(prop)

const TileWrapper = styled('div')`
	position: relative;
	width: 325px;
`

const InfoWrapper = styled('div', { shouldForwardProp })`
	width: 100%;
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	margin-top: 0;
	a {
		text-decoration: none;
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: column;
		flex-basis: 65%;
		max-width: 65%;
	}

	${({ theme }) => theme.breakpoints.up('xl')} {
		flex-direction: column;
		flex-basis: 80%;
		max-width: 80%;
	}
`
const HoverImageWrapper = styled('div')`
	display: flex;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	opacity: 0;
	transition: opacity 0.2s;
	&.active {
		opacity: 1;
	}
`

const Title1 = styled(Typography)`
	text-transform: uppercase;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
	letter-spacing: 0.03em;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`
const Title2 = styled(Typography)`
	text-transform: uppercase;
`

const NamesWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const AdditionalLabelsWrapper = styled('div', { shouldForwardProp })`
	position: absolute;
	top: var(--spacing-1);
	left: ${({ position }) => (position === 'left' ? '8px' : 'auto')};
	right: ${({ position }) => (position === 'right' ? '8px' : 'auto')};
	display: flex;
	flex-direction: column;
	> div {
		margin-bottom: 5px;
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		transition: all 0.3s ease-in-out;
		opacity: 0;
		top: 0;
	}
`

const PriceContainer = styled('div', { shouldForwardProp })`
	margin-top: ${({ variant }) =>
		variant === 'base' || variant === 'advanced' ? '3px' : variant === 'compact' ? '28px' : '0px'};
`

const ListInfoWrapper = styled('div')`
	width: 100%;
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	margin-top: 16px;
	${({ theme }) => theme.breakpoints.up('xsm')} {
		margin-top: 0;
	}
	a {
		text-decoration: none;
	}
	${Title1} {
		margin-bottom: 18px;
	}
	${Title2} {
		text-transform: none;
		line-height: 22px;
		margin-bottom: 8px;
		${({ theme }) => theme.breakpoints.up('md')} {
			margin-bottom: 18px;
		}
	}
`

const RatingWrapper = styled('div', { shouldForwardProp })`
	margin-bottom: 5px;
	text-align: left;
	${({ variant, theme }) => (['base', 'compact'].includes(variant) ? `padding-top: ${theme.spacing(2)};` : '')}
`

const ColorsWrapper = styled('div', { shouldForwardProp })`
	position: ${({ position }) => (position === 'inner' ? 'absolute' : 'relative')};
	bottom: ${({ position }) => (position === 'inner' ? '10px' : 'auto')};
	left: ${({ position }) => (position === 'inner' ? '10px' : 'auto')};
	margin-top: ${({ position }) => (position === 'inner' ? '0' : '10px')};
	margin-bottom: ${({ position }) => (position === 'inner' ? '0' : '10px')};
`
const ColorsSwatches = styled('div')`
	display: flex;
`

const ActionAddToCartStyled = styled(ActionAddToCart)``

export {
	TileWrapper,
	InfoWrapper,
	HoverImageWrapper,
	Title1,
	Title2,
	NamesWrapper,
	AdditionalLabelsWrapper,
	PriceContainer,
	ListInfoWrapper,
	RatingWrapper,
	ColorsWrapper,
	ColorsSwatches,
	ActionAddToCartStyled,
}
