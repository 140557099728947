import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import { SwiperSlide } from 'swiper/react'
import Icon from '../../atoms/icon'
import { TextColumnWrapper } from '@bluheadless/ui/src/molecules/tile/base/tile-base.styled'

const shouldForwardProp = (prop) => !['action', 'aboveTheFold'].includes(prop)

const Container = styled(Box, { shouldForwardProp })`
	position: relative;
	--swiper-navigation-size: 0;
	height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
`

const NavButton = styled(Icon, { shouldForwardProp })`
	background-color: ${({ theme }) => theme.palette.common.white};
	color: var(--color-primary);
	font-size: ${({ theme }) => theme.typography.pxToRem(29)};
	border-radius: 50%;

	${({ action }) => (action === 'prev' ? 'left: 0;' : 'right: 0;')}

	&.swiper-button-disabled {
		pointer-events: auto;
		display: none;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		border: 0;
		border-radius: 50%;
		color: var(--color-primary);
		padding: 0;
	}

	&.swiper-button-lock {
		display: none;
	}
	&.swiper-button-prev {
		left: var(--spacing-2);
	}
	&.swiper-button-next {
		right: var(--spacing-2);
	}
`

const PaginationStyled = styled('div')`
	&.swiper-pagination-horizontal {
		width: auto;
		${({ theme }) => theme.breakpoints.up('md')} {
			width: 100%;
			left: 0;
			transform: none;
		}
	}

	&.swiper-pagination-lock {
		display: none;
	}

	&.swiper-pagination-bullets {
		line-height: 0;
	}

	.swiper-pagination-bullet {
		--swiper-pagination-bullet-horizontal-gap: var(--spacing);
		background-color: transparent;
		height: auto;
		margin: ${({ theme }) => theme.spacing(0, 1)};
		opacity: 1;
		padding: 0;
		width: auto;
		&:before {
			background-color: var(--color-grey60);
			border-radius: 0;
			content: '';
			display: block;
			height: 2px;
			width: var(--spacing-3);
		}
		&-active {
			cursor: default;
			&:before {
				background-color: var(--color-primary);
			}
		}
	}
`

const SwiperSlideStyled = styled(SwiperSlide, { shouldForwardProp })`
	display: ${({ aboveTheFold }) => (aboveTheFold ? 'flex' : undefined)};
	${TextColumnWrapper} {
		${({ theme }) => theme.breakpoints.up('md')} {
			width: calc(100% - var(--spacing-4));
		}
	}
`

const ScrollbarStyled = styled('div')`
	/* Edit swiper variables in css-baseline.js */
	&.swiper-scrollbar {
		background: ${({ theme }) => `var(--swiper-scrollbar-bg-color, ${theme.palette.grey.lighter})`};
		width: calc(100% - (var(--swiper-scrollbar-sides-offset, 1%) * 2));
		left: var(--swiper-scrollbar-sides-offset, 1%);
		right: var(--swiper-scrollbar-sides-offset, 1%);
		bottom: var(--swiper-scrollbar-bottom, 0px);
		height: var(--swiper-scrollbar-height, 4px);
		border-radius: ${({ theme }) => `var(--swiper-scrollbar-border-radius, ${theme.shape.borderRadius}px)`};
		z-index: 1;
		.swiper-scrollbar-drag {
			background: ${({ theme }) => `var(--swiper-scrollbar-drag-bg-color, ${theme.palette.grey.main})`};
			border-radius: ${({ theme }) => `var(--swiper-scrollbar-border-radius, ${theme.shape.borderRadius}px)`};
			cursor: grab;
		}
	}
`

export { Container, NavButton, PaginationStyled, SwiperSlideStyled, ScrollbarStyled }
