/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const Bag2IconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M19.58 22.74H4.42c-.81 0-1.58-.35-2.11-.97-.53-.62-.76-1.43-.64-2.23L3.24 9.3a2.757 2.757 0 012.74-2.35H18c1.38 0 2.53.99 2.74 2.35l1.57 10.24c.12.8-.11 1.61-.64 2.23-.53.62-1.29.97-2.11.97zM5.99 7.95c-.88 0-1.62.63-1.75 1.5L2.67 19.69c-.08.51.07 1.03.41 1.42s.83.62 1.35.62H19.6c.52 0 1.01-.23 1.35-.62.34-.39.49-.91.41-1.42L19.79 9.45c-.13-.87-.87-1.5-1.75-1.5H5.99zM14.27 4.54c-.28 0-.5-.22-.5-.5 0-.98-.8-1.77-1.77-1.77s-1.77.8-1.77 1.77c0 .28-.22.5-.5.5s-.5-.22-.5-.5a2.77 2.77 0 115.54 0c0 .28-.22.5-.5.5z" />
	</svg>
))
Bag2IconSvg.displayName = 'Bag2IconSvg'

const Bag2Icon = forwardRef((props, ref) => <Icon component={Bag2IconSvg} ref={ref} {...props} />)
Bag2Icon.displayName = 'Bag2Icon'

Bag2Icon.defaultProps = {
	...Icon.defaultProps,
}
Bag2Icon.propTypes = {
	...Icon.propTypes,
}

export default Bag2Icon
export { Bag2IconSvg }
