import { elementType, object, oneOf, oneOfType, string } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import CartIcon from '../../atoms/icons/cart-icon'
import Button from '../button'
import Bag2Icon from '../../atoms/icons/bag-2-icon'

const ActionAddToCart = ({ variant, ...props }) => {
	return (
		<Button
			sx={{ padding: variant === 'icon' ? '0 8px 0 0' : '9px 40px', marginRight: variant === 'icon' ? '8px' : '0' }}
			variant={variant}
			{...props}
			StartIcon={null}
			Icon={null}
		/>
	)
}

ActionAddToCart.defaultProps = {
	variant: 'contained',
	Icon: CartIcon,
	StartIcon: Bag2Icon,
	iconProps: {
		fontSize: 'middle',
		color: '#fff',
	},
	label: <FormattedMessage id="action_add_to_cart" />,
}

ActionAddToCart.propTypes = {
	variant: oneOfType([oneOf(['contained', 'outlined', 'text', 'icon']), string]),
	Icon: elementType,
	StartIcon: elementType,
	iconProps: object,
}

export default ActionAddToCart
